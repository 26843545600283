.green-detail
	.bg-wrap
		.desc
			@apply text-center leading-normal
		strong
			@apply text-orange-DE492F
	.block-wrap
		@apply bg-blue-1E4385
		.desc
			p
				@apply mb-3
.green-detail-chart
	.item
	.icon
		display: flex
		height: r(94px)
		width: auto
		img
			width: 100%
			height: 100%
			object-fit: contain
	.desc
		@apply text-center mx-auto
		margin-top: r(15px)
		@screen lg
			margin-top: r(25px)
			max-width: r(290px)
.green-detail-2
	padding-top: r(40px)
	@screen lg
		padding-top: r(60px)
	.title-wrap
		margin-bottom: r(20px)
		.row,.col
			@apply m-0 p-0
		@screen lg
			margin-bottom: r(40px)
		.zone-title
			@screen lg
				@apply mb-0
	.item
		padding: r(30px)
		@apply rounded-[20px] relative overflow-hidden h-full
		&::after
			content: ''
			@apply block absolute pointer-events-none right-0 bottom-0 z-10  transition
			height: r(55px)
			width: r(70px)
			background: url('../img/overlay/6.svg')
			background-repeat: no-repeat
			background-size: contain
			background-position: center
		@screen lg
			padding: r(45px) r(40px)
			@apply rounded-[30px]
	.headline
		@apply mb-3 leading-normal
	.desc
		@apply leading-normal
		p
			&:not(:last-child)
				@apply mb-3
	.block-wrap
		.col
			&:nth-child(1)
				.item
					@apply bg-blue-4E7EC0
			&:nth-child(2)
				.item
					@apply bg-blue-1E4385
			&:nth-child(3)
				.item
					@apply bg-blue-0C233F
.green-detail-3
	@apply relative
	@media screen and ( max-width: 1024px)
		padding-top: r(60px)
	&::after
		+pseudo
		@apply bottom-0 left-0
		height: r(420px)
		width: r(630px)
		background: url('../img/overlay/7.svg')
		@media screen and ( max-width: 1024px)
			height: r(250px)
			width: r(500px)
	.container-fluid
		@apply p-0
	.desc
		p
			&:not(:last-child)
				@apply mb-3