.technical-list 
	padding-top: r(40px) 

	.block-wrap 
		@apply bg-grey-f5
		margin-top: r(60px)
		.row,.col
			@apply m-0 p-0
	.txt
		@apply bg-white border-t-4 border-t-orange-DE492F
		padding: r(30px)
		box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1)
	.fullcontent
		@apply bg-white text-grey-333
		font-size: clamp(15px,r(16px),16px)
		padding: r(30px)
		box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1)
		*
			@apply text-grey-333
			font-size: clamp(15px,r(16px),16px)
		@screen lg
			padding: r(35px) r(60px)
		ul
			padding-left: 0
			list-style-type: none
			margin: r(15px) 0
			li
				@apply text-grey-333 font-normal leading-normal mb-2 start-item
				@screen lg
					@apply mb-3
				&:before
					content: ''
					display: block
					pointer-events: none
					z-index: 9
					height: 16px
					width: 21px
					min-width: 21px
					transition: .3s all ease-in-out
					background: url('../img/logo-3.png')
					background-repeat: no-repeat
					background-size: contain
					background-position: center
					margin-top: 8px
					margin-right: 15px
.technical-list-2
	.block-wrap
		margin-top: r(30px)
		@apply overflow-hidden
		@screen lg
			margin-top: r(40px)
			height: r(875px)

		.row,.col
			@apply m-0 p-0
	.item
		@apply start-item bg-grey-f5 relative
		align-items: center
		box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25)
		padding: r(20px) r(20px)
		height: r(100px)
		@screen lg
			padding: r(12px) r(30px)
			height: r(125px)
		&:first-child
			@apply z-30
			box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25)
		&:nth-child(2)
			background: #EEEEEE
		&:nth-child(3)
			background: rgba(#0C233F ,.2 )
		&:nth-child(4)
			background: rgba(#0C233F ,.4 )
		&:nth-child(5)
			background: rgba(#0C233F ,.6 )
		&:nth-child(6)
			background: rgba(#0C233F ,.8 )
		&:nth-child(7)
			background: #0C233F
		&:nth-child(n+5)
			.title
				@apply text-white
				*
					@apply text-white
	.title
		@apply flex leading-tight
		font-size: clamp(14px,r(16px),16px)
		strong
			display: contents
		&:before
			content: ''
			display: block
			pointer-events: none
			z-index: 9
			height: 16px
			width: 21px
			min-width: 21px
			transition: .3s all ease-in-out
			background: url('../img/logo-3.png')
			background-repeat: no-repeat
			background-size: contain
			background-position: center
			margin-top: 4px
			margin-right: 15px
	.txt-wrap
		@apply bg-blue-0C233F
		box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1)
		padding: r(30px)
		@screen lg
			padding: r(60px)
	.img-wrap
		@apply  w-full
		a
			@apply center-item
			width: auto
			img
				width: auto
				height: 100%
				object-fit: cover
	.content-wrap
		@apply col-ver h-full  bg-blue-0C233F
		justify-content: space-between
		