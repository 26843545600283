.news-detail
	@apply bg-grey-fa
	.block-wrap
		@apply bg-grey-fa
		padding: 0
		@screen lg
			@apply  bg-white
			padding: r(25px) r(30px)
		time
			@apply bg-orange-DE492F rounded-full text-white font-bold mb-3
			padding: 2px 10px
			font-size: 14px
		.zone-title
			@apply text-blue-0C233F uppercase pb-3
			font-size: clamp(24px,r(30px),30px)
			border-bottom: 1px solid #D9D9D9
	.briefcontent
		@apply font-bold text-grey-333
		font-size: clamp(14px,r(16px),16px)
		*
			@apply font-bold text-grey-333
			font-size: clamp(14px,r(16px),16px)
	.fullcontent
		@apply font-normal text-grey-333
		font-size: clamp(14px,r(16px),16px)
		*
			@apply font-normal text-grey-333
			font-size: clamp(14px,r(16px),16px)
		strong
			@apply font-bold
		img
			@apply block mx-auto my-5
		h3,h4
			@apply font-bold 
		ul,ol
			list-style-type: disc
			padding-left: 20px
			li
				@apply mb-3
	.other-news-wrap
		.zone-title
			@apply text-orange-DE492F relative pb-2 mb-0
			&::after
				content: ''
				@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition bg-orange-DE492F
				height: 2px
				width: 50px
		.wrap
			margin-top: r(30px)
		.item
			@apply start-item
			&:not(:last-child)
				margin-bottom: 30px
			.img-src
				width: 140px
				min-width: 140px
				height: 130px
				@screen lg
					width: r(140px)
					min-width: r(140px)
					height: 130px
				a
					display: flex
					height: 130px
					width: auto
					img
						width: 100%
						height: 100%
						object-fit: cover
			.txt
				margin-left: 15px
				@screen xl
					margin-left: 20px
			time
				@apply text-grey-999 font-normal mb-2
				font-size: clamp(12px,r(14px),14px)
			.headline
				a
					@apply text-grey-333 uppercase
					+clamp(16px,1.4,4)