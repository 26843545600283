.about-us
	padding: r(60px) 0 0
	@apply relative
	.row,.col
		@screen lg
			@apply m-0 p-0
	&::before,&::after
		content: ''
		@apply block absolute pointer-events-none  z-10  transition bottom-0
		height: r(330px)
		@media screen and ( max-width: 1024px)
			@apply hidden
	&::before
		@apply left-0 bg-orange-DE492F z-20
		bottom: r(60px)
		width: r(615px)
		@media screen and ( max-width: 1600px)
			width: r(650px)
		@media screen and ( max-width: 1280px)
			width: r(550px)
		@media screen and ( max-width: 1024px)
			@apply hidden
	&::after
		@apply right-0 bg-blue-0C233F
		width: r(1455px)
	.zone-desc
		@apply text-center mb-5
		@screen lg
			margin-bottom: r(50px)
			@apply w-1/2 mx-auto
		strong
			@apply text-orange-DE492F font-bold leading-normal
	.txt
		@screen lg
			padding-right: r(70px)
		.zone-title
			margin-bottom: r(30px)
			@media screen and ( max-width: 1024px)
				@apply text-center
		.desc
			@apply leading-normal
			p
				@apply mb-2
			strong
				@apply font-bold
	.img-wrap
		@media screen and ( max-width: 1024px)
			@apply mb-10
		a
			+img-ratio(600/600)
	.block-wrap
		@apply relative
		@media screen and ( min-width: 1440px) and ( max-width: 1600px)
			margin-top: r(80px)
		@media screen and ( min-width: 1280px) and ( max-width: 1440px)
			margin-top: r(130px)
		@media screen and ( max-width: 1024px)
			@apply mt-0 bg-blue-0C233F py-10
			&::after
				+pseudo
				height: r(303px)
				width: r(430px)
				background: url('../img/overlay/2.svg')
				bottom: 0
				left: 7%
		.title
			@apply text-white font-normal
			font-size: clamp(r(24px), r(30px), 30px)
			*
				@apply text-white font-normal
				font-size: clamp(r(24px), r(30px), 30px)
			strong
				@apply font-bold
		.row
			@apply justify-between 
			&::after
				+pseudo
				height: r(303px)
				width: r(430px)
				background: url('../img/overlay/2.svg')
				bottom: 85%
				left: 7%
				@media screen and ( max-width: 1280px)
					left: 14%
				@media screen and ( max-width: 1024px)
					@apply hidden
	.item-wrap
		@apply flex justify-between
		@media screen and ( max-width: 576px)
			@apply text-center
		@screen lg
			padding: r(70px) 0 r(50px)
		.counter
			@apply text-white leading-none mb-4
			font-weight: 1000
			font-size: r(72px)
			color: transparent
			-webkit-text-stroke-width: .5px
			-webkit-text-stroke-color: white
		
			@screen lg
				margin-bottom: r(30px)
		.headline
			@screen lg
				max-width: r(240px)
	.container
		@apply relative z-40
		font-family: sans-serif
.about-us-2
	.container,.container-fluid,.row,.col
		@screen lg
			@apply m-0 p-0
	.block-wrap-top
		@apply bg-blue-1E4385
		@media screen and ( max-width: 1024px)
			@apply col-ver
		.container
			@apply h-full
			@media screen and ( max-width: 1024px)
				order: 1
		.container-fluid
			@media screen and ( max-width: 1024px)
				order: 2
			.row
				@apply justify-between
		.desc
			@apply leading-normal
	.img-wrap
		a
			@media screen and ( max-width: 1024px)
				+img-ratio(600/960)
			@screen lg
				display: flex
				height: r(600px)
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: cover
	.txt-wrap
		@apply col-hor h-full mb-8
		@screen lg
			@apply mb-0
			padding-top: r(60px)
		.desc
			height: r(160px)
			padding-right: 15px
			overflow-y: auto
			scrollbar-width: thin
			scrollbar-color: #A0C4F4 #cccccc
			&::-webkit-scrollbar
				background: #cccccc
				width: 3px
			&::-webkit-scrollbar-track
				-webkit-box-shadow: inset 0 0 6px #cccccc
				background-color: #cccccc
			&::-webkit-scrollbar-thumb
				background-color: #A0C4F4
			@media screen  and ( min-width:1360px) and ( max-width:1440px)
				height: r(220px)
			@media screen and ( max-width:1360px)
				height: r(200px)
			@media screen and ( max-width:1024px)
				height: auto
		.headline
			@apply mb-2
			@media screen and ( max-width: 1024px)
				@apply text-center mb-5 justify-center w-full
	.txt
		@apply bg-blue-0C233F relative
		padding: r(40px)
		@screen sm
			padding: r(40px) r(60px)
		@screen lg
			padding: r(40px) r(80px)
			margin-left: -30px
		@screen xl
			padding: r(60px) r(100px) r(20px)
		&:before,&:after
			content: ''
			position:  absolute
			width: 0
		&:before
			top: -1px
			left: 0
			border-top: 70px solid $blue-1e
			border-right: 70px solid transparent
			@media screen and ( max-width: 1280px)
				border-top: 40px solid $blue-1e
				border-right: 40px solid transparent
			@media screen and ( max-width: 1024px)
				border-top: 40px solid white
		&:after
			bottom: -1px
			right: 0
			border-bottom: 70px solid white
			border-left: 70px solid transparent
			@media screen and ( max-width: 1280px)
				border-bottom: 40px solid white
				border-left: 40px solid transparent
		.headline
			@apply font-bold
		ul
			padding-left: 0
			list-style-type: none
			margin-top: r(25px)
			li
				@apply text-white font-normal leading-normal mb-3 start-item
				@screen lg
					@apply mb-4
				&:before
					content: ''
					display: block
					pointer-events: none
					z-index: 9
					height: 16px
					width: 21px
					min-width: 21px
					transition: .3s all ease-in-out
					background: url('../img/logo-3.png')
					background-repeat: no-repeat
					background-size: contain
					background-position: center
					margin-top: 8px
					margin-right: 15px
	.block-wrap-bottom
		@apply relative
		@screen lg
			margin-top: r(-200px) 
		@screen xl
			margin-top: r(-250px) 
		@media screen and ( min-width: 1600px)
			margin-top: r(-300px)
		.container
			@apply h-full
			.row,.col
				@apply h-full
				@media screen and ( max-width: 1024px)
					@apply mb-0
	.quotes-wrap 
		@apply relative h-full col-hor
		justify-content: flex-end
		@media screen and ( max-width: 1024px)
			@apply pt-8
		@screen lg
			padding-bottom: r(200px)
		@screen xl
			padding-bottom: r(70px)
		&:before
			content: ''
			display: block
			pointer-events: none
			z-index: 9
			height: 30px
			width: 40px
			min-width: 21px
			transition: .3s all ease-in-out
			background: url('../img/icon/quote.png')
			background-repeat: no-repeat
			background-size: contain
			background-position: center
			margin-top: 8px
			margin-right: 15px
	.quotes-txt 
		font-size: clamp(r(18px), r(24px), 24px)
		@apply relative text-grey-333 leading-tight pl-10 pt-2
		@screen lg
			padding-right: r(60px)
		*
			font-size: clamp(r(18px), r(24px), 24px)
			@apply text-grey-333 leading-tight
		strong
			@apply font-bold text-orange-DE492F
.about-us-3
	padding: r(60px) 0
	.logo
		margin-bottom: r(25px)
		@screen lg
			margin-bottom: r(40px)
		a
			@apply center-item
			display: flex
			height: r(80px)
			width: auto
			img
				width: 100%
				height: 100%
				object-fit: contain
	.first-row 
		@apply mb-0
		@screen lg
			margin-bottom: r(20px)
		.col
			@media screen and ( max-width: 1024px)
				@apply mb-0
		.headline
			@media screen and ( max-width: 1024px)
				@apply text-center mb-4
	.block-wrap 
		@media screen and ( max-width: 1024px)
			padding: r(40px) 0
		.row,.col
			@apply m-0 p-0
		.row
			margin-bottom: r(30px)
			@screen lg
				margin-bottom: r(60px)
			@screen xl
				margin-bottom: r(90px)
			@media screen and ( min-width: 1600px)
				margin-bottom: r(120px)
			&:last-child
				margin-bottom: r(30px)
				@screen lg
					margin-bottom: r(50px)
				@screen xl
					margin-bottom: r(60px)
				@media screen and ( min-width: 1600px)
					margin-bottom: r(90px)
	.img-wrap
		filter: drop-shadow(-10px -10px 30px rgba(0, 0, 0, 0.25))
		a
			+img-ratio(430/690)
	.floor-name
		@apply uppercase
		font-size: r(60px)
		font-weight: 1000
		color: rgba(0, 0, 0, 0.05)
		@screen lg
			writing-mode: vertical-lr
			transform: rotate(180deg)
			font-size: r(100px)
		@media screen and ( max-width: 1024px)
			color: rgba(0, 0, 0, 0.1)
			@apply text-center mb-4
	.headline
		margin-bottom: r(12px)
	.txt
		@apply mb-6
		@screen lg
			@apply start-item mb-0
	.wrap
		@apply h-auto
		@screen lg
			padding: 0 r(50px)
	ul
		li
			@apply border-b border-b-grey-d9 py-2
			@screen lg
				@apply py-3
			&:last-child
				@apply border-b-transparent
	.final-row 
		.desc
			@apply leading-normal
			strong
				@apply font-bold
.about-history 
	@apply bg-blue-0C233F relative
	&::after
		+pseudo
		@apply top-0 left-0
		height: r(630px)
		width: r(930px)
		background: url('../img/overlay/3.svg')
		@media screen and ( max-width: 1024px)
			height: r(250px)
			width: r(500px)
	.container,.container-fluid
		@apply relative z-30
	.headline
		@apply font-normal
	.txt
		@apply text-center 
		margin-top: r(30px)
	.swiper-history-thumb
		@apply relative
		margin-top: r(40px)
		.swiper-slide
			&.swiper-slide-thumb-active
				.year
					.dot
						@apply bg-orange-DE492F
	.year
		@apply text-white font-bold text-center col-ver cursor-pointer
		font-size: clamp(20px,r(24px),24px)
		&:hover
			.dot
				@apply bg-orange-DE492F
	.container-fluid
		@apply relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 top-[7px] z-10  transition w-full bg-white w-full opacity-30
			height: 1px
		.container
			@apply relative
	.img-wrap 
		a
			+img-ratio(690/1230)
	.prev-nav,.next-nav
		@apply absolute  z-30
		top: r(-5px)
		em
			@apply text-white leading-none
			font-size: 24px
	.prev-nav
		left: 0
		@media screen and ( max-width: 576px)
			left: -20px
	.next-nav
		right: 0
		@media screen and ( max-width: 576px)
			right: -20px
	.dot
		width: 15px
		height: 15px
		@apply bg-white  transition
		margin-bottom: r(12px)
		@screen lg
			margin-bottom: r(16px)
	.desc
		margin-bottom: r(40px)
		@screen lg
			margin-bottom: r(80px)
		p
			@apply mb-5