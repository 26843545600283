.brochure-list 
	.top-nav
		margin-bottom:  r(30px)
		@screen lg
			margin-bottom:  r(50px)
	.img-wrap 
		a
			@apply center-item  mx-auto
			height: r(850px)
			width: fit-content
			box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1)
			img
				width: 100%
				height: 100%
				object-fit: contain
	.title
		@apply text-grey-333 font-normal text-center
		font-size: clamp(20px,r(24px),24px)
		&:hover
			@apply underline
	.txt
		@apply center-item
		margin-top: r(20px)
		@screen lg
			margin-top: r(30px)