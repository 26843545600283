.home-service
	.Module
		@apply w-full h-full
	.container-fluid
		@apply p-0
		.row,.col
			@apply m-0 p-0
	.headline
		@apply mb-4
	.item
		@apply relative
		.txt
			@apply absolute-center z-40 text-center w-full
			padding:  0 8%
		.img-src
			@apply relative
	
			&::after
				content: ''
				@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
				background: rgba(0, 0, 0, 0.3)
			a
				+img-ratio(960/640)

	.number
		@apply text-white font-bold center-item leading-none  rounded-full bg-orange-DE492F mx-auto mb-3
		font-size: clamp(r(20px), r(28px), 28px)
		width: r(60px)
		min-width: r(60px)
		height: r(60px)
		@screen lg
			@apply mb-6
			width: r(100px)
			min-width: r(100px)
			height: r(100px)
			font-size: clamp(r(28px), r(36px), 36px)
		@media screen and ( min-width: 1440px)
			margin-bottom: r(60px)
	.mobile-swiper-wrap 
		@apply block
		@screen lg
			@apply hidden
	
	.desk-block-wrap
		@apply hidden
		@screen lg
			@apply flex w-full h-full
	.item-overlay
		@apply absolute-center w-full h-full pointer-events-none
		&.active
			@apply z-40
			img
				filter: brightness(.75) !important
			.img-src
				@apply w-full
			.overlay
				@apply opacity-100
		&.fading
			@apply z-30
		.wrap
			@apply w-full h-full z-10 pointer-events-none relative absolute-center
		.txt-wrap
			@apply relative absolute-y text-center z-20 col-ver h-full
			justify-content: center
			background: rgba(0, 0, 0, 0.3)
			width: 33.33%
			transition: all .5s ease-in-out
		.hover-point
			@apply center-item h-full opacity-0 pointer-events-auto
			width: 33.33%
		.img-src
			@apply h-full relative pointer-events-none
			width: 33.33%
			transition: all .5s ease-in-out
			&::after
				content: ''
				@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
			
			a
				display: flex
				height: 100vh
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: cover
					filter: brightness(.95)
		.overlay
			@apply opacity-0  absolute-y
			padding: 0 r(100px)
			transition: all .5s ease-in-out
			width: 66.66%
		
		&:first-child
			.txt-wrap
				@apply left-0
			.overlay
				left: 33.33%
		&:nth-child(2)
			.img-src,.hover-point
				@apply mx-auto
			.txt-wrap
				@apply left-50 top-50 
				transform: translate(-50%,-50%)
			.overlay
				width: 33.33%
				right: 0
		&:nth-child(3)
			.img-src,.hover-point
				@apply ml-auto
			.txt-wrap
				@apply right-0
			.overlay
				right: 33.33%
				padding-left: r(500px)