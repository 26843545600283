html,body
	color: #333333
	font-weight: 400
	font-size: 4vw
	font-family: 'Mulish', sans-serif
	scroll-behavior: smooth
	@media screen and ( min-width: 420px)
		font-size: 3.6vw
	@screen sm
		font-size: 2.4vw
	@screen md
		font-size: 1.5vw
	@screen lg
		font-size: 1.1vw
	@screen xl
		font-size: 1vw
*
	&:focus
		outline: none
a
	color: #333333
	text-decoration: none
	&:hover
		color: #333333
p
	margin-bottom: 10px
	font-weight: 400
	color: #333333
	font-size: 1rem
figure
	p
		font-weight: 300
		font-size: 1rem
time
	@apply  font-normal  inline-block 
	color: #999999
html
	line-height: 1.2
	-webkit-text-size-adjust: 100%
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0)
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section
	display: block
body
	margin: 0
	font-weight: 400
	line-height: 1.2
	color: #333333
	text-align: left
	background-color: #ffffff
hr
	box-sizing: content-box
	height: 0
	overflow: visible

.frm-btn-reset
	display: none !important
.col-12
	@apply w-full
	-webkit-box-flex: 0
	-ms-flex: 0 0 auto
	-webkit-box-orient: vertical
	-webkit-box-direction: normal
	-ms-flex-direction: column
	flex: 0 0 auto
	flex-direction: column
	padding: 0 15px
.col-lg-8
	padding: 0 15px
	@screen lg
		width: 66.666667%
.col-lg-4
	padding: 0 15px
	@screen lg
		width: 33.333333%

.col-xs-12
	padding: 0 15px
	width: 100%
.col-lg-3
	padding: 0 15px
	@screen lg
		width: 25%
.col-lg-9
	padding: 0 15px
	@screen lg
		width: 75%
.row 
	@apply flex flex-wrap justify-start
.col
	flex: 0 0 auto
	@apply flex flex-col
	padding: 0 15px
	.col
		margin-bottom: 0
.row
	margin: 0 -15px -30px -15px
	> .col
		margin-bottom: 30px

.container, .container-fluid
	width: 100%
	padding-right: 15px
	padding-left: 15px
	margin-right: auto
	margin-left: auto
.col-match-height
	@apply flex w-full flex-col h-full
@function r($size)
	@return ($size/1920px*100rem)

$primary: #FF0000
$blue-4e: #4E7EC0 
$blue-1e: #1E4385
$blue-0c: #0C233F
$ora: #DE492F
$grey-111: #111111
$grey-151: #151515
$grey-333: #333333
$grey-666: #666666
$grey-999: #999999
$grey-f3: #F3F3F3
$grey-f5: #F5F5F5
$grey-dd: #DDDDDD
$dark: #000000
$white: #ffffff