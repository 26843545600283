.floor-renting
	.zone-desc
		@apply text-center
		strong
			@apply text-orange-DE492F
	.partner-swiper
		padding: r(40px)
		@screen lg
			padding: r(50px) 0 r(60px)
	.single-swiper 
		margin-bottom: r(30px)
		.img-wrap
			a
				+img-ratio(790/1410)
	.item
		@media screen and ( max-width: 576px)
			@apply col-ver
		@screen sm
			@apply start-item items-start
		.number
			@apply bg-orange-DE492F center-item text-white font-bold leading-none mb-4 sm:mr-2 sm:mb-0
			font-size: clamp(20px,r(24px),24px)
			width: r(50px)
			height: r(50px)
			min-width: r(50px)
		.desc
			@media screen and ( max-width: 576px)
				@apply text-center
	.img
		a
			@apply center-item
			height: r(100px)
			width: auto
			img
				width: 100%
				height: 100%
				object-fit: contain
.floor-renting-2
	.zone-title
		margin-bottom: r(10px)
	.swiper-floor-thumb
		@apply bg-blue-1E4385
		margin-top: 20px
		.swiper-slide
			@screen lg
				width: fit-content !important
			&.swiper-slide-thumb-active
				@apply bg-blue-4E7EC0
				.floor
					@apply border-transparent
				+ .swiper-slide
					.floor
						@apply border-l-transparent
			&:first-child
				.floor
					padding-left: 0
					@apply border-l-transparent
	.number
		@apply text-white font-bold leading-none
		font-size: r(32px)
		margin-top: 10px
		@media screen and ( max-width: 1024px)
			@apply whitespace-nowrap
		@screen lg
			@apply leading-[.9]
			font-size: r(48px)
		br
			@apply hidden
	.floor
		margin: r(18px) 0
		@apply border-l border-blue-4E7EC0
		padding: 0 r(30px)
		span
			@apply text-white
			font-size: 16px
	.swiper-floor-main
		.item
			@apply relative
			&::after
				content: ''
				@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
				background: linear-gradient(180deg, rgba(30, 67, 133, 0) 44.27%, rgba(30, 67, 133, 0.95) 100%)
		.img-wrap
			a
				@media screen and ( max-width: 1024px)
					+img-ratio(9/16)
				@screen lg
					+img-ratio(750/1920)
		.txt
			@apply absolute-x w-full text-center z-40 w-10/12
			bottom: r(30px)
			@screen lg
				bottom: r(70px)
				@apply w-1/2
		.desc
			font-size: clamp(18px,r(24px),24px)
			*
				font-size: clamp(18px,r(24px),24px)
			@media screen and ( max-width: 576px)
				font-size: clamp(16px,r(18px),18px)
				*
					font-size: clamp(16px,r(18px),18px)
.floor-renting-3
	.block-wrap 
		box-shadow: 6px 6px 20px 0px #0000001A
		padding: r(30px)
		@apply h-full
		@screen lg
			padding: r(60px) 
		.zone-desc
			@apply mb-6
			@screen lg
				margin-bottom: r(40px)
		.item
			@media screen and ( max-width: 1024px)
				@apply col-ver text-center
			.number
				@apply bg-orange-DE492F col-ver
				justify-content: center
				width: r(120px)
				height: r(120px)
			.counter
				@apply text-white font-bold leading-none
				font-size: r(40px)
				@screen lg
					font-size: r(56px)
					@apply leading-[.9]
			span
				@apply text-white font-normal mt-2 block w-full center-item
				font-size: r(20px)
				@screen lg
					font-size: r(26px)
			.desc
				font-size: clamp(14px,r(16px),16px)
				@apply text-blue-1E4385 font-bold mt-2
	.content-wrap
		@apply h-full col-ver
		justify-content: space-between
		box-shadow: 6px 6px 20px 0px #0000001A
		.col
			&:not(:last-child)
				@apply border-r border-r-grey-ee
		.title
			font-size: 16px
			@apply text-blue-1E4385 font-bold leading-none start-item mb-3
			align-items: center
			@screen lg
				@apply mb-2
			&:before
				content: ''
				display: block
				pointer-events: none
				z-index: 9
				height: 16px
				width: 21px
				min-width: 21px
				transition: .3s all ease-in-out
				background: url('../img/logo-3.png')
				background-repeat: no-repeat
				background-size: contain
				background-position: center
				margin-top: 8px
				margin-right: 10px
				@screen lg
					width: 40px
					min-width: 40px
					height: 30px
		.wrap
			padding: r(30px) 0
			&:last-child
				padding-bottom: 0
			&:not(:last-child)
				@apply border-b border-b-grey-ee
			.row,.col
				@screen lg
					@apply mb-0
				
	.price-item 
		@apply text-center
		.desc
			margin-bottom: r(10px)
		.number
			@apply text-orange-DE492F 
			font-weight: 1000
			font-size: r(36px)
		strong
			@apply font-bold mt-1
			font-size: 14px
	.block-white
		@apply w-full
		padding: r(30px)
		@screen lg
			padding: r(60px) r(30px) r(30px) r(30px)
	.block-blue
		@apply bg-blue-1E4385
		padding: r(30px)
		@screen lg
			padding: r(35px) r(30px)
	.contact-info
		@apply start-item mb-4
	.img-wrap
		@apply h-full
		a
			@apply w-full h-full center-item
			img
				object-fit: cover
				@apply h-full
.floor-renting-4
	@apply relative
	.img-wrap 
		a
			@media screen and ( max-width: 1024px)
				+img-ratio(9/16)
			@screen lg
				+img-ratio(990/1920)
	.container 
		@screen sm
			@apply absolute-x z-40
			top: r(60px)
		@screen lg
			top: r(90px)
	.desc
		@apply leading-normal ml-4
		@media screen and ( max-width: 576px)
			@apply text-grey-333
			*
				@apply text-grey-333
	.title
		@apply uppercase text-orange-DE492F
		font-size: r(40px)
		font-weight: 1000
		@screen sm
			font-size: r(60px) 
		@screen lg
			writing-mode: vertical-lr
			transform: rotate(180deg)
			font-size: r(100px)
		@media screen and ( max-width: 1024px)
			@apply text-center mb-4
	.txt
		@media screen and ( max-width: 576px)
			@apply py-8
		@screen lg
			@apply flex