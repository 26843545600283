.home-location
	@apply relative 
	&::after
		content: ''
		@apply block absolute pointer-events-none right-0 bottom-0 z-10  transition h-full
		background: #f1f1f1
		width: r(460px)
		@media screen and ( max-width: 1024px)
			@apply hidden
	@media screen and ( max-width: 1024px)
		padding: r(60px) 0
		.container
			.col,.row
				@apply mb-0
	.content-wrap
		@apply relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition
			width: r(610px)
			background: url('../img/overlay/1.svg')
			background-repeat: no-repeat
			background-size: 100% 100%
			background-position: center
			height: r(500px)
			left: r(-150px)
			bottom: r(-100px)
			@media screen and ( max-width: 576px)
				left: 0
				@apply w-full h-[200px] bottom-0
	.img-wrap
		a
			@screen lg
				display: flex
				height: r(730px)
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: contain
	.container-fluid
		@screen lg
			padding: 0 r(50px)
	.txt-wrap
		@apply bg-blue-0C233F
		padding: r(30px) r(50px)
		@screen lg
			padding: r(50px) r(60px)
		&:before,&:after
			content: ''
			position:  absolute
			width: 0
		&:before
			top: -1px
			left: -1px
			border-top: 60px solid white
			border-right: 60px solid transparent
			@media screen and ( max-width: 1024px)
				border-top: 40px solid white
				border-right: 40px solid transparent
		&:after
			bottom: -1px
			right: -1px
			border-bottom: 60px solid #f1f1f1
			border-left: 60px solid transparent
			@media screen and ( max-width: 1024px)
				border-bottom: 40px solid white
				border-left: 40px solid transparent
		ul
			margin-top: r(30px)
		li
			@apply mb-4 start-item text-white font-bold
			align-items: center
			font-size: clamp(14px, 0.83rem, 16px)
			p
				@apply mb-0
			*
				@apply text-white font-bold
				font-size: clamp(14px, 0.83rem, 16px)
			@screen lg
				margin-bottom: r(30px)
			span
				@apply bg-orange-DE492F center-item text-white font-bold mr-3 relative z-40
				font-size: r(24px)
				min-width: r(50px)
				width: r(50px)
				height: r(50px)
				@screen lg
					@apply mr-4
					font-size: r(30px)
					min-width: r(70px)
					width: r(70px)
					height: r(70px)