.view-360-list
	.top-nav
		margin-bottom:  r(30px)
		@screen lg
			margin-bottom:  r(50px)
	.image-wrap
		@apply relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full 
			background: rgba(#000,.5 )
		&:hover
			&::after
				background: rgba(#000,.2 )
		.icon
			@apply absolute-center z-40 object-cover center-item pointer-events-none
			height: 100px
			width: auto
			img
				width: auto
				height: 100%
				object-fit: contain
		a
			@apply center-item
			width: auto
			img
				width: auto
				height: 100%
				object-fit: cover
.vr-popup-modal-wrap
	width: 100% !important
	height: 100% !important
	a
		@apply overflow-hidden
		display: block
		width: auto
		height: 100% !important
		iframe
			width: 100%
			height: 100%
		@media screen and ( max-width: 1024px)
			height: 400px
		@media screen and ( max-width: 576px)
			height: 300px
			iframe,object,embed
				max-width: 100%