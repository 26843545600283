.top-banner
	@apply relative
	.img-wrap
		@apply relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 48.19%, rgba(0, 0, 0, 0.748) 100%)
		@media screen and ( max-width: 1024px)
			+img-ratio(9/16)
		@screen lg
			+img-ratio(650/1920)
	.txt
		@apply absolute-x z-40 w-full
		bottom: r(60px)
		@screen md
			bottom: r(100px)
	.title
		@apply text-white font-bold uppercase text-center px-4
		font-size: r(28px)
		@screen sm
			font-size: r(32px)
		@screen lg
			font-size: r(56px)