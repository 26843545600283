.search-page
	padding: r(60px) 0
	h1
		letter-spacing: -2px
		margin-bottom: 20px
		font-size: 45px
		line-height: 55px
		font-weight: 500
		color: #333333
		@media (max-width: 992px)
			font-size: 24px
			line-height: 30px
		&.center
			text-align: center
	.btn-reindex
		display: none
	.form-inline
		@apply relative
	.searchcontrols
		.form-inline
			position: relative
			&:before
				position: absolute
				content: "\f002"
				font-family: 'Font Awesome 6 Pro'
				top: 0
				right: 10px
				z-index: 1
				font-size: 24px
				color: #4285f4
				width: 44px
				height: 44px
				display: flex
				align-items: center
				justify-content: center
				pointer-events: none
		.frm-btn
			position: absolute
			top: 0
			right: 10px
			background-color: rgba(0,0,0,0)
			z-index: 2
	input[type="text"]
		width: 100%
		height: 100%
		padding-left: 20px
		padding-right: 54px
		border: 1px solid #dfe1e5
		background-color: #fff
		color: #333
		font-size: .875rem
		font-weight: 400
		height: 44px
		border-radius: 22px
		box-shadow: 0 0 0 0px rgba(0,0,0,0)
		transition: .25s cubic-bezier(.4,0,.2,1)
		outline: none
		&:focus
			box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28)
			border-color: rgba(223,225,229,0)
	input[type="submit"]
		border-radius: 2px
		user-select: none
		background-color: rgba(0,0,0,0)
		border: 0
		box-shadow: none
		cursor: pointer
		text-align: center
		display: flex
		align-items: center
		justify-content: center
		height: 40px
		outline: none
		width: 44px
		height: 44px
		border-radius: 50%
		font-size: 0
		position: absolute
		right: 0
		bottom: 0
	.text-danger
		margin-top: 20px
		font-size: 15px
		color: #333
		font-style: italic
		font-weight: 600
	.searchresultsummary
		margin-bottom: 20px
	.searchresults
		margin-top: 30px
		.modulepager
			&:first-child
				display: none
	.searchresultlist
		margin-bottom: 30px
	.searchresult
		margin-bottom: 30px
		box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28)
		padding: 16px
		border-radius: 8px
		&:last-child
			margin-bottom: 0
		h3
			font-size: 18px
			line-height: 1.33
			font-weight: 400
			margin-bottom: 10px
			a
				color: #1A0DAB
				text-decoration: none
				font-weight: 500
				&:hover
					text-decoration: underline
		.searchresultdesc
			color: #545454
			line-height: 1.54
			word-wrap: break-word
			font-size: small
			font-weight: 400
			margin-bottom: 10px
		.searchterm
			color: #6a6a6a
			font-weight: bold
			font-style: normal
			line-height: 1.54
			word-wrap: break-word
			font-size: small
		hr
			display: none !important
	.search-title
		@apply text-24 text-grey-333 font-bold mb-4 block uppercase
	.text-main
		@apply font-normal text-18 text-26 normal-case
	.searchresultsummary
		@apply text-16 text-grey-333 font-semibold
	.kw-desc
		@apply font-bold text-blue-1E4385
.search-toggle
	padding: 0
	margin-right: r(40px)
	@apply h-full center-item transition
	@media screen and ( max-width: 1024px)
		@apply mr-2
	@media screen and ( max-width: 576px)
		padding: 0 r(12px)
		@apply ml-0
	em
		font-size: 18px
		@apply leading-none text-blue-1E4385
		@media screen and ( max-width: 1024px)
			font-size: 20px
.search-overlay
	z-index: 1030
	height: 25vh
	top: 80px
	@apply bg-white shadow-lg opacity-0 pointer-events-none transition-all fixed w-full flex items-center justify-center left-0
	@media (max-width: 1024px)
		height: 40vh
		top: 60px
	&.active
		@apply opacity-100 pointer-events-auto
		.searchbox
			transform: translateY(0%)
			@apply delay-100 opacity-100
		.close
			@apply opacity-100
	.close
		z-index: 2
		bottom: 100%
		width: r(40px)
		height: r(40px)
		@apply flex justify-center pointer-events-none absolute items-center opacity-0 transition-all duration-1000
		i
			font-size: r(30px)
			@apply text-grey-333 justify-center
	.searchbox
		transform: translateY(100%)
		@apply flex  transition-all relative duration-1000 opacity-0 w-full
		input
			@apply  border-b border-dark text-grey-333  rounded-none placeholder:text-16 placeholder:text-grey-333 font-light p-0 text-24
			height: 40px
			background: transparent
			@screen lg
				width: 600px
		button
			@apply pl-4 h-full transition-all
			em
				@apply  text-grey-333 font-light border-none rounded-sm flex items-center justify-center rounded-sm h-full transition-all
				font-size: 32px
.productsearchresults
	main
		padding-top: 120px !important
	.zone-title
		font-size: clamp(32px,40px)
		*
			font-size: clamp(32px,40px)
		@screen lg
			font-size: clamp(40px,46px)
			*
				font-size: clamp(40px,46px)
	header
		+boxshadow
.search-result-wrap
	.row
		@apply justify-start
	.section-title
		@apply mb-5
		.text-main
			@apply text-blue-4E7EC0
	.news-item
		.txt
			padding: r(20px) !important