.home-floor
	@apply bg-gradient bg-blue-0C233F bg-blue-4E7EC0 bg-blue-1E4385 relative overflow-hidden
	mix-blend-mode: normal
	.container
		@apply relative z-40
	@media screen and ( max-width: 1024px)
		padding: r(60px) 0
		.container
			.col,.row
				@apply mb-0
		.first-row
			>.col
				&:first-child
					order: 2
	&::before,&::after
		+pseudo
	&::before
		height: r(500px)
		width: r(610px)
		background: url('../img/overlay/1.svg')
		@apply left-0
		bottom: r(-100px)
	&::after
		height: r(303px)
		width: r(430px)
		background: url('../img/overlay/2.svg')
		@apply top-0  right-0
	.block-wrap
		.row
			@apply mb-0
			@screen lg
				@apply mb-2
	.wrap-left
		@apply mt-[20px]
	.txt
		@media screen and ( max-width: 1024px)
			@apply mt-6
		@screen lg
			@apply col-hor h-full
	.block-title
		@media screen and ( max-width: 1024px)
			@apply text-center
	.floor-image-wrap
		@media screen and ( max-width: 1024px)
			@apply mt-5 pointer-events-none
	.block-wrap
		@media screen and ( max-width: 1024px)
			@apply hidden
	.mobile-block-wrap
		@apply hidden
		@media screen and ( max-width: 1024px)
			@apply block
			margin-bottom: r(40px)
	.floor-img-wrap
		@apply h-full center-item
		svg
			@media screen and ( max-width: 1024px)
				@apply pointer-events-none
		@screen lg
			a
				@apply center-item
				width: auto
				height: r(700px)
				img,svg
					width: auto
					height: 100%
					object-fit: contain
		@media screen and ( min-width: 1440px)
			a
				height: r(750px)
		.path-g
			@apply pointer-events-auto
			path
				@apply pointer-events-auto
			&:hover
				.st0
					fill: $ora
		g
			path
				@apply pointer-events-none
			@screen lg
				&.active
					>text
						@apply opacity-100
						&.sub-text
							@apply opacity-100

					.st0
						fill: $ora
			&:hover
				>text
					@apply opacity-100
					&.sub-text
						@apply opacity-100
		.st0
			transition: .3s all  ease-in-out
			@screen lg
				&:hover
					fill: $ora
		text
			@apply pointer-events-none
			&.sub-text
				@apply opacity-0 transition block
				fill: #fff
				font-family: 'Mulish'
				font-size: 14px
				@media screen and ( max-width: 1024px)
					@apply 	block
	.item-floor
		@apply hidden
		&.show
			@apply flex
	.headline
		a
			&:hover
				@apply text-white
		.ora
			&:hover
				@apply text-orange-DE492F
	.Module-1406
		@apply h-full
		.ModuleContent
			@apply h-full