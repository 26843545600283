.home-utility
	background: url('../img/bg/home-2.svg')
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	@media screen and ( max-width: 1024px)
		padding: r(60px) 0
	.item
		@apply relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 33.85%, rgba(#000,.5 ) 62.5%)

		&:hover
			&::after
				@apply opacity-0
	.img-src
		a
			+img-ratio(460/330)
	.txt
		@apply absolute-center w-full h-full col-hor transition pointer-events-none z-40
		justify-content: flex-end
		padding: r(30px)
		@screen lg
			padding: r(40px)  r(30px)
	.icon
		@apply flex bg-orange-DE492F p-1
		height: r(60px)
		width: r(60px)
		min-width: r(60px)
		margin-bottom: r(20px)
		img
			width: 100%
			height: 100%
			object-fit: contain
	.txt-wrap
		@apply col-hor h-full
		@media screen and ( max-width: 1024px)
			@apply items-center