.primary-banner
	@apply relative z-10
	.banner-container
		@apply relative
		@media screen and ( max-width: 1024px)
			position: relative
			display: block
			height: 0
			overflow: hidden 
			padding-top: 1/1 *100%
			.swiper
				position: absolute
				width: 100%
				height: 100%
				top: 0
				left: 0
	.wrap
		@apply relative
		@media screen and ( max-width: 1024px)
			@apply static h-full
	.swiper
		.image-wrap
			@apply relative
			&::before,&::after
				content: ''
				@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full 
				
				background-repeat: no-repeat !important
				background-size: cover !important
				background-position: center !important
			&::before
				background: url('../img/bg/home-1-2.svg')
			&::after
				background: url('../img/bg/home-1.svg')
			@screen lg
				a
					display: block
					height: 100vh
					width: auto
					img
						width: 100%
						height: 100%
						object-fit: cover
	
			@media screen and ( max-width: 1024px)
				a
					+img-ratio(1/1)
	.swiper-pagination
		@apply  end-item
		width: auto !important
		bottom: r(120px) !important
		@screen lg
			right: r(380px)
			left: unset !important
		@media screen and ( max-width: 1024px)
			bottom: 30px !important
			left: 50% !important
			transform: translateX(-50%)
		@media screen and ( max-width: 576px)
			bottom: 15px !important
		.swiper-pagination-bullet
			@apply text-white font-bold opacity-100 bg-transparent border-2 border-transparent center-item transition leading-none
			width: r(38px)
			height: r(38px)
			font-size: r(18px)
			@media screen and ( max-width: 768px)
				width: r(32px)
				min-width: r(32px)
				height: r(32px)
				font-size: r(16px)
			&.swiper-pagination-bullet-active
				@apply border-white
	.txt
		@apply absolute-y z-40 
		max-width: r(450px) 
		@media screen and ( max-width: 1024px)
			@apply left-[15px]
		@screen lg
			@apply right-[60px] 
	.title
		@apply text-white font-normal  leading-tight text-left
		font-size: r(24px)
		@screen lg
			font-size: r(30px)
		p
			@apply mb-0
		*
			@apply text-white font-normal  leading-tight mb-6 text-left
			font-size: r(24px)
			@screen lg
				font-size: r(30px)
		span
			@apply text-orange-DE492F font-bold
			font-size: clamp(28px,r(40px),40px)
			*
				@apply text-orange-DE492F font-bold
				font-size: clamp(28px,r(40px),40px)
			@screen xl
				font-size: clamp(40px,r(60px),60px)
				*
					font-size: clamp(40px,r(60px),60px)