footer
	@apply bg-blue-1E4385 py-6
	.container
		@apply gap-item
		@media screen and ( max-width: 576px)
			@apply col-ver
	.logo
		@media screen and ( max-width: 576px)
			order: 2
		a
			@apply start-item
			height: 50px
			width: auto
			img
				width: auto
				height: 100%
				object-fit: contain
.Module-1365
	.ActionMenuBody
		top: unset !important
		bottom: 100% !important
.contact-info
	@media screen and ( max-width: 576px)
		order: 1
		@apply mb-6
	.ModuleContent
		@apply end-item
	a
		@apply center-item bg-orange-DE492F rounded-full overflow-hidden
		width: 40px
		height: 40px
		&:not(:last-child)
			margin-right: r(20px)
		em
			font-size: 18px
			@apply text-white leading-none
	.frm-thanks
		@apply text-grey-111
		*
			@apply text-grey-111
