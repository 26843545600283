.pad-6
	padding: r(50px) 0
.pad-8
	padding: r(50px) 0
	@screen lg
		padding: r(50px) 0 r(70px) 
.block-wrap-center
	@apply relative z-50
	.container
		@screen lg
			@apply absolute-center
.block-wrap-center-2
	@apply relative z-50
	.container-fluid
		@screen lg
			@apply absolute-center
.title-wrap
	@media screen and ( max-width: 768px)
		@apply m-0 p-0
		.col
			@apply m-0 p-0
.carousel__button 
	&.is-close
		top: 0 !important
		svg
			@apply text-blue-1E4385
.fancybox__content
	.img-wrap 
		a
			@apply center-item
.fp-watermark
	display: none !important
.view-360-page
	.fancybox__slide
		padding: 0 !important