.btn
	span,em
		transition: .4s all  ease-in-out
	&.btn-primary
		padding: 0 r(24px)
		height: r(60px)
		&::after
			content: ''
			@apply block absolute-y pointer-events-none z-10  transition rounded-full bg-orange-DE492F rounded-full left-0
			height: r(60px)
			width: r(60px)
			@media screen and ( max-width: 1024px)
				height: r(50px)
				width: r(50px)
		&:hover
			&::after
				transform: translateY(-50%) scale(.8)
			em
				transform: translateX(5px)
		&.blue
			span,em
				@apply text-blue-1E4385
			em
				&::before
					@apply text-blue-1E4385
			&:hover
				em
					transform: translateY(5px)
		span
			@apply text-white font-bold relative z-50
			font-size: 16px
			@media screen and ( max-width: 1024px)
				font-size: 14px
		em
			font-size: 10px
			@apply leading-none text-white inline-block ml-3
			@media screen and ( max-width: 1024px)
				font-size: 8px
	&.btn-view-detail
		&.grey
			span
				@apply text-grey-333
			em
				@apply bg-orange-DE492F text-white border-orange-DE492F border
			&:hover
				em
					@apply bg-white text-orange-DE492F
				
		em
			@apply text-orange-DE492F leading-none bg-white center-item rounded-full transition
			font-size: clamp(16px, r(18px), 18px)
			width: r(42px)
			height: r(42px)
		span
			@apply text-white font-bold transition
			margin-left: r(14px)
			font-size: clamp(14px, r(16px), 16px)
.nav-prev,.nav-next
	@apply   z-10 transition  absolute top-1/2 translate-y-50    cursor-pointer center-item  rounded-full transition
	width: 50px
	height: 50px
	line-height: 50px
	@media screen and ( max-width: 1440px)
		width: 42px
		height: 42px
		line-height: 42px
	@media screen and ( max-width:  1024px)
		@apply static mx-6
		transform: none
		width: 40px
		height: 40px
		line-height: 40px
	em
		@apply inline-block transition
		font-size: 16px
		height: 16px
		line-height: 16px
		@media screen and ( max-width: 1440px)
			font-size: 14px
			height: 14px
			line-height: 14px
.swiper-nav-wrap
	@media screen and ( max-width: 1024px)
		@apply center-item pt-8
	&.white
		background: none
		.nav-next,.nav-prev
			em
				@apply text-white
			&:hover
				em
					@apply text-white

.nav-next
	right: -70px
	@media screen and ( min-width: 1024px) and ( max-width: 1440px)
		right: -50px
.nav-prev
	left:  -70px
	@media screen  and ( min-width: 1024px) and ( max-width: 1440px)
		left: -50px
.wrap-gap,.wrap-center,.wrap-left,.wrap-right,.ajax-center-wrap
	@apply flex items-center
	a
		&:not(:last-child)
			@apply mr-2
.wrap-center,.ajax-center-wrap
	@apply  justify-center
.wrap-left
	@apply justify-start
.wrap-right
	@apply justify-end
.wrap-gap
	@apply justify-between
.ajax-center-wrap
	@apply mt-8
	&.disble-btn
		@apply opacity-0 pointer-events-none