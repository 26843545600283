main
	@apply overflow-hidden
	@media screen and ( max-width: 1024px)
		padding-top: 60px
body
	&.header-white
		.search-toggle
			em
				@apply text-white
				@media screen and ( max-width: 1024px)
					@apply text-blue-1E4385
		.language-wrapper 
			.current-language
				em
					@apply text-white
	&:not(&.homepage)
		main
			padding-top: 60px
			@screen lg
				@apply pt-0
		#menu-parallax,.Module-1371
			@apply hidden
		.search-toggle
			em
				@apply text-blue-1E4385
		.language-wrapper 
			.current-language
				em
					@apply text-blue-1E4385

	&.overlay-bg
		header
			@apply bg-transparent #{!important}
	&.news-detail-page,&.normal-page
		main
			padding-top: 60px
			@screen lg
				padding-top: 100px !important
		header
			@apply bg-white
			
		.breadcrumb-banner-overlay
			.top-banner
				@apply hidden
			.global-breadcrumb
				@apply w-full
				box-shadow: none
				@screen md
					position: static !important
					transform: none !important
					bottom: r(50px)
					min-height: auto
					ol
						@apply start-item
					li
						&:first-child
							@apply pl-0
						&::after
							@apply text-grey-666
					a,span
						@apply text-grey-666
	&.minimize
		main
			padding-top: 60px
			@screen lg
				padding-top: 100px !important
		header
			@apply bg-white
	&.homepage
		footer
			@apply hidden
		main
			padding-top: 60px
			@screen lg
				padding-top: 0
		.search-toggle
			em
				@apply text-white
				@media screen and ( max-width: 1024px)
					@apply text-blue-1E4385
		.nav-brand
			.st0
				@screen lg
					fill: #fff
		&.fp-viewing-thirdPage
			.nav-brand
				.st0
					fill: #1E4385
			#menu-parallax
				li
					&.active
						a
							span
								&:first-child
									color: white !important
				a
					@apply text-blue-1E4385
					*
						@apply text-blue-1E4385
		.language-wrapper 
			.current-language
				em
					@apply text-white
					@media screen and ( max-width: 1024px)
						@apply text-blue-1E4385
