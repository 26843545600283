=boxshadow
	box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.09)
.four-swiper,.trinity-swiper,.single-swiper,.double-swiper
	@apply relative
.frm-thanks
	@apply my-16 p-8 text-center
	margin: 0 auto
	width: 500px
	h3,h4,h5
		@apply text-primary font-bold text-primary text-16 mb-3
	p,span
		@apply text-14 text-grey-333 font-normal
	@media screen and ( max-width: 1024px)
		width: 320px
.tab-panel
	@apply hidden
	&.active
		@apply block
.edit-link
	width: 30px
	height: 30px
	pointer-events: auto
	.fa-pencil
		width: 18px
		height: 18px
		background-size: contain
		background-repeat: no-repeat
		background-position: center
		background-image: url("data:image/svg+xml;charset=utf-8,<svg  viewBox='0 0 18 18'  xmlns='http://www.w3.org/2000/svg'><path d='M6.35473 15.7485L2.27202 11.6658L12.1468 1.79106L16.2295 5.87378L6.35473 15.7485ZM1.89039 12.385L5.63556 16.1302L0.0205078 18L1.89039 12.385ZM17.4852 4.62344L16.7771 5.33159L12.6889 1.24347L13.3971 0.535326C14.1104 -0.178442 15.2672 -0.178442 15.9805 0.535326L17.4852 2.04001C18.1934 2.75572 18.1934 3.90795 17.4852 4.62344Z' fill='#094594'/></svg>")
.custom-select
	width: 100%
	select
		-webkit-appearance: none
		-moz-appearance: none
		background: url('../img/icon/chevron-down.png') no-repeat calc(100% - 15px), rgba(white,.5) !important
		background-position: 95% 50%
.table-responsive
	@media screen and ( max-width:768px)
		overflow-x: auto
	table
		@apply w-full
		@media screen and ( max-width:768px)
			white-space: nowrap
		thead
			tr
				th
					@apply bg-primary text-white text-16 font-bold text-left  py-4  px-6
					@media screen and ( min-width:1024px)
						&:first-child
							@apply pl-12
		tbody
			tr
				&:nth-child(even)
					background: #f5f5f5
				td
					@apply py-4 px-6
					&:first-child
						width: 25%
					@media screen and ( min-width:1024px)
						@apply pl-12
					&:last-child
						width: 25%
						@apply  text-right pr-12
						.btn-view-more
							span
								color: #af9a56
.scollbar-wrap
	height: 300px
	padding-right: 15px
	overflow-y: auto
	scrollbar-width: thin
	scrollbar-color: #A0C4F4 #cccccc
	&::-webkit-scrollbar
		background: #cccccc
		width: 3px
	&::-webkit-scrollbar-track
		-webkit-box-shadow: inset 0 0 6px #cccccc
		background-color: #cccccc
	&::-webkit-scrollbar-thumb
		background-color: #A0C4F4
	@media screen and ( max-width:1024px)
		height: auto
.qradio
	input
		background-repeat: no-repeat
		background-position: center
		appearance: none
		@apply block
		width: 20px
		height: 20px
		background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>")
		&:checked
			background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff0000' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>")

.fixed-side-nav
	@apply fixed right-[20px] opacity-100 pointer-events-auto bottom-[60px]
	transition: .4s all  ease-in-out
	z-index: 120
	@media screen and ( max-width: 1280px)
		@apply right-[15px] opacity-100
		bottom: 50px
	
	ul
		list-style-type: none
		@apply col-item pl-0
		li
			@apply mb-[10px]
			&:first-child
				em
					font-size: r(24px)
			&:nth-child(2)
				a
					@apply px-1
			&:last-child
				a
					@apply bg-primary
					em
						@apply text-white
						font-size: 20px
						line-height: 1
						font-weight: 600
						margin-right: 5px
						@media screen and ( max-width: 1024px)
							font-size: 14px
			a
				@apply center-item bg-white rounded-full overflow-hidden
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
				height: r(50px)
				width: r(50px)
				border: 1px solid white
				@screen xl
					height: r(60px)
					width: r(60px)
				em
					@apply text-primary center-item
					font-size: r(28px)
					@media screen and ( max-width: 576px)
						font-size: r(24px)
					@screen xl
						font-size: r(32px)
	.hotline
		@apply relative
		&:hover
			.wrap
				@apply opacity-100
	.wrap
		@apply absolute bg-white absolute right-full top-50 translate-y-50 w-max rounded-[10px] p-4 py-3 opacity-0 transition
		+boxshadow 
		p
			@apply start-item text-14
		a
			background: none !important
			width: auto !important
			height: auto !important
			box-shadow: none
			@apply font-bold
#menu-parallax
	@apply fixed top-50 translate-y-50  col-hor 
	left: r(45px)
	z-index: 30
	align-items: flex-start
	@media screen and ( max-width: 1024px)
		@apply hidden
	li
		@apply  w-fit flex justify-end items-center relative  mb-1
		&.active
			span
				@apply opacity-100
				&:first-child
					@apply rounded-full bg-orange-DE492F 
		span
			font-size: 16px
			@apply font-bold  inline-block w-fit inline-block opacity-0  text-white transition
			&:first-child
				width: 36px
				height: 36px
				margin-right: 10px
				@apply center-item 
		a
			@apply start-item
			align-items: center
			span
				&:first-child
					@apply opacity-100
.side-nav-tool
	@apply fixed
	z-index: 121
	right: r(30px)
	bottom: r(140px)
	@media screen and ( max-width: 1024px)
		right: r(30px)
		width: r(45px)
		height: r(45px)
		bottom: 20%
	@media screen and ( max-width: 576px)
		right: r(15px)
	
	a
		@apply  bg-white rounded-full center-item cursor-pointer transition
		+boxshadow
		width: r(45px)
		height: r(45px)
		em
			font-size: r(18px)
			@apply leading-none text-grey-333 transition
	.hotline-info
		@apply mb-2
		&:hover
			@apply bg-blue-4E7EC0
			em
				@apply text-white