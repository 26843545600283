.wrap-form
	@apply relative
	.row
		box-shadow: none !important
	.fa-exclamation-triangle
		@apply absolute text-12 font-light 
		bottom: -20px
		left: 0
		color: #f30000
	.col
		@apply mb-4 relative
		&:last-child
			@apply mb-6
	.form-group
		@apply relative 
		margin-bottom: 30px !important
		input[type='text'],textarea,select
			height: 50px
			background: transparent
			border-bottom: 1px solid #D2D2D2
			border-radius: 0
			padding: 0 14px
			font-size: clamp(14px, r(16px), 16px)
			@apply  text-white 
			@media screen and ( max-width:1024px )
				height: 40px
			&::placeholder
				@apply   font-normal text-white
				font-size: clamp(14px, r(16px), 16px)
	.form-group
		&.form-select
			overflow: hidden
			position: relative
			&:before
				content: '\F0140'
				font-family: 'Material Design Icons'
				display: block
				position: absolute
				right: 15px
				top: 50%
				color: $grey-111
				transform: translateY(-50%)
				z-index: 1
				pointer-events: none
				font-size: 16px
			select
				-webkit-box-sizing: border-box
				-moz-box-sizing: border-box
				box-sizing: border-box
				-webkit-appearance: none
				-moz-appearance: none
				&::-ms-expand 
					display: none
		textarea
			@apply p-4
			height: 7rem
			@screen lg
				height: 120px
	.frm-btnwrap
		width: fit-content !important
		.frm-btn
			@apply relative start-item
			padding: 0 r(24px)
			height: r(60px)
			&::before
				font-family: 'icomoon'
				content: "\e901"
				@apply block absolute-y pointer-events-none  z-10  transition text-blue-1E4385 left-full
				height: 6px
				width: 27px
				font-size: 12px
			&::after
				content: ''
				@apply block absolute-y pointer-events-none z-10  transition rounded-full bg-orange-DE492F rounded-full left-0
				height: r(60px)
				width: r(60px)
				@media screen and ( max-width: 1024px)
					height: r(50px)
					width: r(50px)
			&:hover
				&::after
					transform: translateY(-50%) scale(.8)
			input[type='submit']
				@apply text-blue-1E4385 font-bold relative z-30
				font-size: 16px
		
			

.frm-btn-reset
	display: none
.frm-captcha
	margin: 40px 0 0  0 !important
	display: inline-flex
	flex-direction: row-reverse
	img
		height: 44px !important
		max-width: auto
		max-width: 150px
		@media screen and ( max-width:1200px)
			max-width: 150px
			
	@media screen and (max-width:576px)
		flex-direction: column-reverse
	.frm-captcha-input
		@apply mt-0
		margin-left: 50px
		@media screen and ( max-width:576px)
			margin-left: 0
			@apply mt-4
		label
			display: none
		input
			padding: 0 10px
			margin-top: 0
			height: 30px
			@media screen and ( max-width:1280px)
				max-width: 200px
			@media screen and ( max-width:1200px)
				max-width: 150px
				
			@media screen and ( max-width:1024px)
				max-width: 200px
				
			@media (max-width: 767.98px)
				margin-left: 20px
			@media (max-width: 576.98px)
				margin-left: 0
	.rcRefreshImage
		position: absolute
		opacity: 1
		cursor: pointer
		height: 0
		width: 0
		right: -5px
		top: 5px
		color: #ffffff
		z-index: 0
		font-size: 0
		&:before
			font-family: 'Material Design Icons'
			position: absolute
			top: 0
			right: -40px
			opacity: 1
			z-index: 99
			color: #9d9080
			font-size: 30px
			content: "\F0450"
			display: flex
			justify-content: center
			align-items: flex-start
			@media screen and ( max-width:576px)
				height: 50px
@media screen and ( max-width:500px )
	#ctl00_mainContent_ctl05_captcha_ctl00
		display: none
#ctl00_mainContent_ctl02_captcha_ctl01
	position: relative
#ctl00_mainContent_ctl06_btnReset,#ctl00_mainContent_ctl05_captcha_ctl00,#ctl00_mainContent_ctl08_captcha_ctl00,#ctl00_mainContent_ctl07_captcha_ctl00
	display: none
#ctl00_mainContent_ctl06_txtCaptcha
	margin-left: 10px
#ctl00_mainContent_ctl06_captcha_CaptchaLinkButton
	#text
		display: none
.RadCaptcha
	@apply relative
	@media screen and ( max-width:767.5px)
		margin-left: 0
	span
		@apply hidden
	#ctl00_mainContent_ctl04_captcha_ctl00
		display: block !important
		position: absolute
		left: 0
		top: 100%
		@apply text-primary text-12
	>div
		>div
			display: flex
			position: relative
			flex-direction: column-reverse
			height: 40px !important
			margin-top: 0
			@media (max-width: 767.98px)
				margin-right: 0
				width: 180px