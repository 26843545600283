.floor-list
	.zone-title
		@media screen and ( max-width: 576px)
			@apply text-center
	.row
		@apply justify-start
	.floor-img-wrap
		@apply h-full center-item mb-6
		svg
			@media screen and ( max-width: 1024px)
				@apply pointer-events-none
		@screen lg
			a
				@apply center-item
				width: auto
				height: r(700px)
				img,svg
					width: auto
					height: 100%
					object-fit: contain
		@media screen and ( min-width: 1440px)
			a
				height: r(750px)
		.path-g
			@apply pointer-events-auto
			path
				@apply pointer-events-auto
			&:hover
				.st0
					fill: $ora
		g
			path
				@apply pointer-events-none
			@screen lg
				&.active
					>text
						@apply opacity-100
						&.sub-text
							@apply opacity-100

					.st0
						fill: $ora
			@screen lg
				&:hover
					>text
						@apply opacity-100
						&.sub-text
							@apply opacity-100
		.st0
			transition: .3s all  ease-in-out
			@screen lg
				&:hover
					fill: $ora
		text
			@apply pointer-events-none
			&.sub-text
				@apply opacity-0 transition block
				fill: #fff
				font-family: 'Mulish'
				font-size: 14px
				@media screen and ( max-width: 1024px)
					@apply 	block
	.item
		@apply flex
		@media screen and ( max-width: 576px)
			@apply col-ver
	.img-wrap
		width: r(450px)
		min-width: r(450px)
		a
			@media screen and ( max-width: 1024px)
				+img-ratio(260/450)
			@screen lg
				display: flex
				height: r(260px)
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: contain
	.txt
		@apply col-hor
		@media screen and ( max-width: 576px)
			@apply pt-5 w-full
	.headline
		@apply mb-3
		@screen lg
			@apply mb-4
		.ora
			@apply text-orange-DE492F block
		a,span
			@apply text-grey-333
	