.floor-detail
	padding-top: r(40px) 
	.zone-title
		margin-bottom: r(28px)
		@media screen and ( max-width: 1024px)
			@apply mb-2
	.txt-wrap
		@screen lg
			@apply flex
	.txt
		@apply w-full
		@screen lg
			padding-left: r(40px)
	.headline
		@apply mb-4
		@screen lg
			margin-bottom: r(25px)
	ul
		@apply w-full
		li
			@apply text-grey-333 font-normal py-2 w-full
			@screen lg
				@apply py-3
			&:not(:last-child)
				@apply border-b border-b-grey-d9
	.floor-name
		@apply uppercase
		font-size: r(60px)
		font-weight: 1000
		color: rgba(0, 0, 0, 0.05)
		@screen lg
			writing-mode: vertical-lr
			transform: rotate(180deg)
			font-size: r(100px)
		@media screen and ( max-width: 1024px)
			color: rgba(0, 0, 0, 0.1)
			@apply text-left mb-4
	.img-wrap
		a
			filter: drop-shadow(-10px -10px 30px rgba(0, 0, 0, 0.25))
			+img-ratio(430/690)

.floor-img-wrap
	a
		@apply center-item
		svg
			@media screen and ( max-width: 576px)
				height: 450px
				max-height: 450px
	g
		&:hover
			>text
				@apply opacity-100
	.st0
		&:hover
			fill: $ora
	text
		@apply pointer-events-none transition
		&.sub-text
			@apply opacity-0
			fill: #fff
			font-family: 'Mulish'
			font-size: 16px

.floor-detail-gallery 
	.container-fluid
		@screen lg
			@apply p-0
	.row
		@media screen and ( max-width: 1024px)
			margin: 0 -5px -10px -5px
			.col
				padding: 0 5px
				margin-bottom: 10px
	.filter-display-wrapper
		.col
			@apply hidden
			&:nth-child(1),&:nth-child(2)
				width: 50%
	.img-wrap
		a
			+img-ratio(530/945)
	.btn-primary
		em
			transform: rotate(90deg) !important