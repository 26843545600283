.home-contact-us
	@media screen and ( max-width: 1024px)
		padding: r(60px) 0
		.block-title
			@apply text-center mb-4
	ol
		margin: r(27px) 0
	li
		@apply text-white font-normal start-item mb-4
		font-size: clamp(14px, r(16px), 16px)
		*
			@apply text-white font-normal
			font-size: clamp(14px, r(16px), 16px)
		p
			@apply my-auto 
		em
			width: 32px
			height: 32px
			min-width: 32px
			@apply center-item rounded-full bg-orange-DE492F overflow-hidden leading-none font-light
			font-size: 18px
			margin-right: r(20px)
	.row
		@media screen and ( max-width: 1024px)
			@apply m-0 p-0
			.col
				@apply m-0 p-0
	.form-block-wrap
		@screen lg
			margin-top: r(20px)
	.wrap-form
		.fa-exclamation-triangle
			left: 20px
			bottom: -30px
			font-weight: 700
			font-style: italic
		.frm-btnwrap
			margin:  15px r(35px) 0 auto
			.frm-btn
				&::before
					@apply text-white
				input[type='submit']
					@apply text-white
		.form-group 
			margin-bottom: 15px !important
			input[type='text'], textarea
				border-bottom: 1px solid rgba(#fff, .25)
	.frm-thanks
		@apply text-white
		*
			@apply text-white