.service-list
	@apply bg-blue-0C233F
	.row,.col
		@apply m-0 p-0
	.txt-wrap
		@apply col-hor h-full py-8
		justify-content: flex-start
		@screen lg
			padding: r(80px)
		.zone-title
			a
				&:hover
					@apply text-white underline
	.container
		@apply h-full
	.container-fluid
		@screen lg
			@apply p-0
	.block-wrap-center
		@apply z-30
		&:first-child
			&::before
				+pseudo
				height: r(300px)
				width: r(430px)
				background: url('../img/overlay/4.svg')
				@apply bottom-0 right-0
				@media screen and ( max-width: 1024px)
					height: r(250px)
					width: r(500px)
		&:nth-child(2)
			&::before
				+pseudo
				height: r(610px)
				width: r(500px)
				background: url('../img/overlay/5.svg')
				@apply left-50 translate-x-50
				bottom: r(-300px)
				@media screen and ( max-width: 1024px)
					height: r(250px)
					width: r(500px)
		&:nth-child(even)
			.row
				@apply flex-row-reverse
	.img-wrap
		a
			+img-ratio(600/900)
	.wrap-left
		margin-top: r(30px)
		@apply w-full
		@screen lg
			margin-top: r(60px)
	.container,.container-fluid
		@apply  z-40