.top-nav
	ul
		@apply gap-item
		margin: 0 -5px
		li
			@apply w-full
			padding:  0 5px
			&:hover
				a
					@apply underline
			&.active
				a
					@apply bg-orange-DE492F text-white border-orange-DE492F
		a
			@apply center-item text-blue-1E4385 font-normal border-blue-1E4385 border w-full
			padding: r(12px) r(20px)
			height: r(48px)
			font-size: clamp(14px,r(16px),16px)
	@media screen and ( max-width: 1024px)
		@apply overflow-x-auto
		ul
			@apply start-item whitespace-nowrap pb-2
			
.zone-nav
	ul
		@apply end-item
		li
			border-right: 1px solid #D9D9D9
			@apply leading-none
			&.active
				a
					@apply text-orange-DE492F underline
			&:hover
				a
					@apply underline
			&:last-child
				border-right: 0
				a
					@apply pr-0
		a
			@apply text-grey-666 font-normal leading-none uppercase
			font-size: clamp(14px,r(16px),16px)
			padding: 0 r(20px)
.news-list
	.top-row
		margin-bottom: 0
	.title-wrap
		margin: r(40px) 0 r(30px)
		@screen md
			@apply gap-item
		@media screen and ( max-width: 576px)
			@apply block
		@screen lg
			margin: r(60px) 0 r(25px)
	.zone-title
		@apply uppercase
		@media screen and ( max-width: 768px)
			@apply text-center
		@screen sm
			@apply mb-0
	.news-item
		&:not(&.big)
			.txt
				background: #F1F1F1
	.zone-nav
		@media screen and ( max-width: 768px)
			@apply overflow-x-auto
			ul
				@apply start-item whitespace-nowrap pb-1
				justify-content: flex-start