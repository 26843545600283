//Type 1 first child icon
.modulepager
	@apply flex items-center justify-center pt-8 pb-0
	@media screen and ( max-width:1024px)
	padding-bottom: 30px
	ul
		padding: 0
		margin: 0
		position: relative
		li
			display: inline-block
			padding: 0 10px
			&:last-child()
				position: relative
				&:after
					font-family: 'Material Design Icons'
					content: '\f142'
					font-size: 20px
			&.active
				a,span
					@apply bg-orange-DE492F
			&:hover
				a,span
					@apply underline
			span,a
				width: 40px
				height: 40px
				@apply bg-blue-1E4385 text-white font-normal center-item transition 
				font-size: 18px
				text-decoration: none
				@media screen and ( max-width:1024px)
					height: 32px
					width: 32px
					
