.language-wrapper
	@apply p-0  pr-0 relative overflow-hidden flex  mr-7 transition
	z-index: 121
	@media screen and ( max-width:1280px)
		@apply mr-4
	@media screen and ( max-width: 1024px )
		@apply p-0  ml-0 mr-2
	&:hover 
		@apply overflow-visible
		.language-list
			@apply  opacity-100 pointer-events-auto
		.current-language
	.current-language
		@apply relative z-40 center-item
		em
			@apply text-blue-1E4385 leading-none inline-block px-1
			font-size: 18px
			@media screen and ( max-width: 1024px)
				font-size: 20px
	.language-list
		@apply absolute  m-0 p-0 top-full  opacity-0 pointer-events-none transition pt-1  bg-white px-4 pb-1
		@screen lg
			@apply  left-1/2  translate-x-50
		@media screen and ( max-width: 1024px)
			@apply flex justify-center items-center left-0 flex-col p-0 px-4 mt-4
		li
			@apply inline-block
			@media screen and ( max-width: 1024.1px)
				@apply mb-2
			&.active
				a
					@apply text-orange-DE492F
			a
				@apply text-12  uppercase  font-bold whitespace-nowrap
				&:hover
					@apply underline
				@media screen and ( max-width: 1024.1px)
					@apply text-20