.breadcrumb-banner-overlay
	@apply relative
	.global-breadcrumb
		@screen md
			@apply absolute-x z-30
			bottom: r(50px)
			min-height: auto
			ol
				@apply center-item
			li
				&::after
					@apply text-white
			a,span
				@apply text-white
.global-breadcrumb
	@apply start-item py-2 w-full
	min-height: 50px
	box-shadow: 5px 5px 25px 0px #0000000F
	.container
		@apply flex items-center
	ol
		@apply p-0 list-none
	li
		@apply inline-flex relative leading-none
		padding:  0 14px
		&:after
			content: '/'
			@apply text-grey-666 block font-light absolute-y pointer-events-none z-10 left-full
			font-size: 16px
			height: 16px
			width: 12px
			@media screen and ( max-width: 1024px)
				font-size: 14px
		&:last-child
			&:after
				@apply hidden
			a
				@apply text-grey-666
	a,span
		font-size: 14px
		@apply text-grey-666 font-normal