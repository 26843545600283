.home-about
	@apply bg-gradient bg-blue-0C233F bg-blue-4E7EC0 bg-blue-1E4385 relative overflow-hidden
	mix-blend-mode: normal
	@media screen and ( max-width: 1024px)
		padding: r(60px) 0
		.container
			.col,.row
				@apply mb-0
	&::before,&::after
		+pseudo
	&::before
		height: r(500px)
		width: r(610px)
		background: url('../img/overlay/1.svg')
		@apply left-0
		bottom: r(-100px)
	&::after
		height: r(303px)
		width: r(430px)
		background: url('../img/overlay/2.svg')
		@apply top-0 
		left: r(610px)
	.block-title
		span
			@apply whitespace-nowrap
	.item-wrap
		@apply flex  items-center
		margin: 0 -10px
		@screen xl
			margin: 0 -20px
		@media screen and ( min-width: 1600px)
			padding-right: r(160px)
		@media screen and ( max-width: 1024px)
			justify-content: center
	.item
		@apply relative 
		margin: 0 10px
		@screen lg
			width: r(230px)
		@screen xl
			margin: 0 20px
		&:hover
			.img-warp 
				&::after
					@apply opacity-0
			.txt
				&::after
					@apply opacity-100
		&:nth-child(2)
			@screen lg
				width: r(270px)
				.img-warp 
					a
						height: r(720px)
	.icon
		@apply start-item relative z-40
		height: r(70px)
		max-width: r(70px)
		width: auto
		@media screen and ( max-width: 576px)
			height: r(40px)
			max-width: r(40px)
		img
			width: 100%
			height: 100%
			object-fit: contain
	.title
		@apply text-white font-bold mt-3 relative z-50
		font-size: r(24px)
		@screen lg
			@apply mt-5
	.txt
		@apply absolute bottom-0 left-0 z-30 w-full h-full col-hor  pointer-events-none  transition
		justify-content: flex-end
		padding: r(20px)
		@screen lg
			padding: r(44px) r(24px) r(44px) r(37px)
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full opacity-0
			background: linear-gradient(180deg, rgba(12,35,63,0.25) 20%, rgba(12,35,63,0.9) 100%)
	.img-warp 
		@apply relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full 
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 44.27%, rgba(0, 0, 0, 0.5) 100%)
		a
			@media screen and ( max-width: 1024px)
				@apply center-item
			@screen lg
				display: flex
				height: r(600px)
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: cover
			&:hover
				img
					transform: scale(1.3)
		
	.txt-desc
		@apply text-white font-bold 
		font-size: r(24px)
		*
			@apply text-white font-bold 
			font-size: r(24px)
		@screen lg
			font-size: r(36px)
			*
				font-size: r(36px)
	.wrap-left
		margin-top: r(30px)
	.txt-wrap
		@media screen and ( max-width: 1024px)
			@apply text-center
			.wrap-left
				@apply justify-center