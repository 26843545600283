.desc
	@apply text-grey-333 font-normal leading-tight
	font-size: clamp(14px, r(16px), 16px)
	*
		@apply text-grey-333 font-normal leading-tight
		font-size: clamp(14px, r(16px), 16px)
	strong
		@apply font-bold
		*
			@apply font-bold
	&.white
		@apply text-white
		*
			@apply text-white
	&.bold
		@apply font-bold
		*
			@apply font-bold
	&.t-24
		font-size: clamp(20px, r(24px), 24px)
		*
			font-size: clamp(20px, r(24px), 24px)
	&.t-18
		font-size: clamp(16px, r(18px), 18px)
		*
			font-size: clamp(16px, r(18px), 18px)