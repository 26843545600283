.gallery-list 
	.top-nav
		margin-bottom:  r(30px)
		@screen lg
			margin-bottom:  r(50px)
	.zone-title
		@apply text-blue-1E4385 uppercase mb-4
		@screen lg
			@apply  mb-6
	.row
		.col
			&:nth-child(1),&:nth-child(2)
				@screen sm
					width: 50%
		@media screen and ( max-width: 576px)
			margin: 0 -5px
			.col
				margin-bottom: 10px
				padding: 0 5px
	&.video-list
		.row
			.col
				&:nth-child(1),&:nth-child(2)
					.gallery-item
						&.video
							&::after
								height: r(64px)
								width: r(64px)
								@screen lg
									height: r(108px)
									width: r(108px)
.gallery-item
	@apply relative
	.img-wrap
		@apply relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-1/2
			background: linear-gradient(180deg, rgba(30, 67, 133, 0) 44.27%, rgba(30, 67, 133, 0.95) 100%)
		a
			+img-ratio(390/690)
	.txt
		@apply absolute-x z-20 pointer-events-none font-bold text-white w-full px-8 text-center
		bottom: r(24px)
		font-size: clamp(16px,r(20px),20px)
		@media screen and ( max-width: 576px)
			font-size: clamp(14px,r(18px),18px)
	&.video
		&::after
			content: ''
			@apply block absolute-center pointer-events-none left-0 bottom-0 z-10  transition
			height: r(64px)
			width: r(64px)
			background: url('../img/icon/play.png')
			background-repeat: no-repeat
			background-size: contain
			background-position: center
			@screen lg
				height: r(84px)
				width: r(84px)