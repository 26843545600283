.home-media
	background: url('../img/bg/home-2.svg')
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	@media screen and ( max-width: 1024px)
		padding: r(60px) 0
	.block-title
		@screen sm
			@apply mb-0
	.title-wrap
		margin-bottom: r(30px)
		@media screen and ( max-width: 576px)
			@apply col-ver
	.row
		@media screen and ( max-width: 1024px)
			margin-bottom: -15px
.news-item
	@apply relative
	&.big
		.img-src
			a
				@screen lg
					height: r(555px)
				@screen xl
					height: r(550px)
		.txt
			@apply absolute left-0 bottom-0 z-30 w-auto 
			background: rgba(#fff,.95 )
			@screen lg
				padding: r(20px) r(35px) r(40px) r(35px)
			@media screen and ( min-width: 1024px)
				max-width: 60%
			@media screen and ( min-width: 1280px)
				max-width: 50%
	.img-src
		a
			@media screen and ( max-width: 1024px)
				+img-ratio(300/325)
			@screen lg
				display: flex
				height: r(300px)
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: cover
	.txt
		@apply bg-white 
		padding: r(20px) r(30px) 
		@screen lg
			padding: r(20px) r(30px) r(40px) r(30px)
	time
		@apply bg-orange-DE492F rounded-full text-white font-bold mb-2
		padding: 2px 10px
		font-size: 14px
	.desc
		+clamp(16px,1.2,3)
	.headline
		margin-bottom: 10px
		a
			+clamp(18px,1.2,4)
		@media screen and ( max-width: 1024px)
			a
				+clamp(18px,1.2,2)
	&:hover
		.headline
			@apply underline