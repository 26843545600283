.header-site
	@apply fixed top-0 left-0 w-full transition
	z-index: 122
	height: 100px
	@media screen and ( max-width: 1024px)
		height: 60px
		@apply bg-white
	&.is-active
		.nav-brand,.language-wrapper,.search-wrap
			@apply opacity-0
	.container-fluid
		@apply relative gap-item h-full
		padding: 0 r(50px)
		@media screen and ( max-width: 1024px)
			padding: 0 15px

	.nav-brand
		@apply transition
		a
			display: flex
			height: 60px
			width: auto
			@media screen and ( max-width: 1024px)
				height: 40px
			svg
				width: auto
				height: 100%
				object-fit: contain
		.st0
			transition: .4s all  ease-in-out
			fill: #1E4385
	.header-wrap-right
		@apply end-item
.menu-toggle
	@apply bg-orange-DE492F col-ver
	justify-content: center
	z-index: 122
	width: 72px
	height: 72px
	@media screen and ( max-width: 1024px)
		width: 50px
		height: 50px
	span
		@apply text-white font-bold mt-1 transition
		font-size: 18px
		@media screen and ( max-width: 1024px)
			font-size: 12px
	.hamburger 
		@media screen and ( max-width: 1024px)
			margin-top: 4px
	.txt-1
		@apply block
	.txt-2
		@apply hidden
	&.is-toggle
		.txt-1
			@apply opacity-0 hidden
		.txt-2
			@apply opacity-100 block
.site-menu-wrap
	@apply fixed w-full h-full  left-0   opacity-0 pointer-events-none
	justify-content: center
	z-index: 121
	background: radial-gradient(50% 50% at 50% 50%, #102E53 17.19%, #0C233F 71.88%)
	@screen lg
		@apply  top-0  col-ver
		justify-content: center
	@media screen and ( max-width: 1024px)
		@apply flex pt-8
		top: 60px
	&.is-open
		@apply opacity-100 pointer-events-auto transition-cubic
	&::before,&::after
		+pseudo
	&::before
		height: r(500px)
		width: r(610px)
		background: url('../img/overlay/9.svg')
		bottom: r(-100px)
		left: 0
		@media screen and ( max-width: 1024px)
			height: r(350px)
			width: r(410px)
			bottom: r(-50px)
	&::after
		height: r(460px)
		width: r(660px)
		background: url('../img/overlay/8.svg')
		@apply top-0 right-0
		@media screen and ( max-width: 1024px)
			height: r(260px)
			width: r(460px)
		@media screen and ( max-width: 576px)
			@apply hidden
	.logo 
		margin-bottom: r(20px)
		@media screen and ( max-width: 1024px)
			@apply hidden
		@screen lg
			margin-bottom: r(40px)

		a
			@apply center-item
			height: r(100px)
			width: auto
			@media screen and ( max-width: 1024px)
				height: r(80px)
			img
				width: 100%
				height: 100%
				object-fit: contain
.nav-primary-menu
	.nav
		@apply center-item
		@media screen and ( max-width: 1024px)
			@apply col-ver
			
		.nav-link
			@apply relative
			padding: r(10px) 0 
			@media screen and ( max-width: 1024px)
				width: 320px
				@apply center-item flex-col
			@screen lg
				padding: r(10px) r(20px)
			&.current-page
				.title
					a
						@apply opacity-100
						&::after
							@apply opacity-100
				>a
					@apply opacity-100
					&::after
						@apply opacity-100
			a
				@apply text-white font-bold uppercase relative
				padding-bottom: 2px
				opacity: .75
				font-size: clamp(20px,r(24px),24px)
				&::after
					content: ''
					@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full bg-orange-DE492F opacity-0
					height: 1px 
		.drop-down
			.title
				@media screen and ( max-width: 1024px)
					@apply center-item relative w-full 
				em
					@apply block text-white absolute-y right-[5px]
					font-size: 20px
					@screen lg
						@apply hidden
			@screen lg
				&:hover
					.nav-sub
						@apply opacity-100 pointer-events-auto
		.nav-sub
			@media screen and ( max-width: 1024px)
				@apply hidden
			@screen lg
				@apply absolute-x top-full left-0  col-ver opacity-0 pointer-events-none transition
				width: max-content
			.nav-link-sub
				padding: r(20px) 0
				@media screen and ( max-width: 1024px)
					@apply center-item
				&.active
					a
						@apply text-orange-DE492F
				@screen lg
					&:hover
						a
							@apply underline
				a
					font-size: clamp(16px,r(18px),18px)
					@apply font-normal opacity-100 transition