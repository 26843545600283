.service-detail
	@apply bg-white
	padding-top: r(40px)
	.row
		@media screen and ( max-width: 768px)
			.col
				@apply mb-0
	.zone-desc
		@media screen and ( max-width: 768px)
			@apply mb-6
		@screen md
			@apply mt-6
.service-detail-2
	.tabs
		margin-top: r(40px)
		@screen lg
			margin-top: r(60px)
		@screen xl
			margin-top: r(90px)
	.container-fluid
		@apply p-0
	.tab-menu
		@apply center-item
		li
			@apply text-grey-333 font-bold center-item transition
			font-size: clamp(16px,r(20px),20px)
			padding: r(8px) r(20px)
			height: r(50px)
			@screen lg
				padding: r(8px) r(30px)
				font-size: clamp(20px,r(24px),24px)
				height: r(70px)
			&.active
				@apply bg-orange-DE492F text-white
	.tab-panel
		@media screen and ( max-width: 576px)
			padding: 0 15px
	.tab-content
		@apply bg-blue-0C233F
		padding: r(60px) 0
	.service-detail-swiper 
		@apply relative
		.nav-prev
			left: r(-80px)
			@media screen and ( min-width: 1024px) and ( max-width: 1440px)
				left: r(-100px)
		.nav-next
			right: r(-80px)
			@media screen and ( min-width: 1024px) and ( max-width: 1440px)
				right: r(-100px)
		.swiper
			@apply overflow-visible
	.swiper-slide
		&.swiper-slide-active
			.img-src
				&::after
					@apply opacity-0
			.txt
				
				@screen lg
					width: r(350px)
					min-width: r(350px)
					@apply opacity-100 h-auto
					padding: r(65px)  r(30px)
	.item
		@apply flex
		@media screen and ( max-width: 576px)
			@apply col-ver
	.img-src
		@apply w-full h-full relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(chuttersnap-aEnH4hJ_Mrs-unsplash.jpg)
		a
			@screen lg
				display: flex
				height: r(400px)
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: cover
			@media screen and ( max-width: 1024px)
				+img-ratio(400/650)
	.txt
		@apply bg-white
		@media screen  and ( min-width: 576px) and ( max-width: 1024px)
			padding: r(30px)
			width: r(350px)
			min-width: r(350px)
		@media screen and ( max-width: 576px)
			order: 2
			@apply p-6 w-full
		@screen lg
			@apply  opacity-0  w-0 h-0
	.title
		@apply text-orange-DE492F font-bold block mb-2
		font-size: clamp(16px,r(16px),18px)
		@screen lg
			@apply mb-3
		