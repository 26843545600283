.contact-us
	@apply relative
	padding-top: r(40px)
	@media screen and ( max-width: 1024px)
		padding-bottom: r(60px)
	.Module-1367
		@apply h-full
		.ModuleContent
			@apply h-full
	.block-title
		@apply uppercase text-blue-1E4385
	ol
		margin: r(27px) 0
	li
		@apply text-grey-333 font-normal start-item mb-4
		font-size: clamp(14px, r(16px), 16px)
		*
			@apply text-grey-333 font-normal
			font-size: clamp(14px, r(16px), 16px)
		em
			width: 32px
			height: 32px
			min-width: 32px
			@apply center-item rounded-full bg-orange-DE492F overflow-hidden leading-none text-white
			font-size: 18px
			margin-right: r(20px)
		p
			@apply my-auto
	.img-wrap 
		@apply block w-full relative
		&:hover
			&::after,&:before
				@apply opacity-100

		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-20  transition w-full h-full opacity-0
			background: rgba(#000,.5 )
			background-repeat: no-repeat
			background-size: cover
			background-position: center
		&::before
			content: "\f002"
			font-family: 'Font Awesome 6 Pro'
			@apply block absolute-center pointer-events-none z-50  transition w-full h-full text-center text-white center-item opacity-0
			font-size: 36px
			@screen lg
				font-size: 42px
		a
			+img-ratio(350/570)
	.block-wrap
		@apply h-full col-ver
		justify-content: center
		@screen lg
			padding-right: r(60px)
	.contact-form
		@apply bg-grey-f5 relative
		padding: r(30px)
		&::after
			content: ''
			@apply block absolute pointer-events-none left-full bottom-0 z-10  transition w-full h-full bg-grey-f5
		@screen lg
			padding: r(70px) r(60px)
	.description
		@apply mb-3 leading-normal
		span
			@apply text-orange-DE492F
		@screen lg
			@apply mb-5
	.wrap-form
		.form-group
			input[type='text'],textarea
				@apply text-grey-333 bg-white
				padding: 0 r(20px)
				height: r(48px)
				border: 1px solid #D1D1D1
				&::placeholder
					@apply text-grey-999
					font-size: 16px
					@media screen and ( max-width: 1024px)
						font-size: 14px
			textarea
				padding: r(20px)
				height: r(90px)
				@screen lg
					height: r(145px)
			.fa-exclamation-triangle
				bottom: -27px
				left: 16px
.map-display
	.container-fluid
		@apply p-0
.map-wrapper 
	@apply h-full
	a
		display: block
		height: r(610px)
		@apply overflow-hidden
		text-align: center
		width: 100%
		@media screen and ( max-width: 1280px)
			height: r(410px)
		@media screen and ( max-width: 576px)
			height: r(310px)
		iframe
			@apply inline-block w-full h-full