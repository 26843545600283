.block-title
	@apply text-white font-bold leading-[1.3] mb-6
	font-size: r(40px)
	*
		font-size: r(40px)
		@apply text-white font-bold leading-[1.3] 
	@screen lg
		font-size: r(56px)
		*
			font-size: r(56px)
	&.blue
		@apply text-blue-4E7EC0 block
	.blue
		@apply text-blue-4E7EC0 block
.headline
	@apply font-bold text-white
	font-size: clamp(r(24px), r(30px), 30px)
	*
		@apply font-bold text-white
		font-size: clamp(r(24px), r(30px), 30px)
	&.ora
		@apply text-orange-DE492F block
		*
			@apply text-orange-DE492F
	.ora
		@apply text-orange-DE492F block
		*
			@apply text-orange-DE492F
	&.blue
		@apply text-blue-0C233F
		*
			@apply text-blue-0C233F
	&.blue-1e
		@apply text-blue-1E4385
		*
			@apply text-blue-1E4385
	&.t-36
		font-size: clamp(r(28px), r(36px), 36px)
		*
			font-size: clamp(r(28px), r(36px), 36px)
	&.t-24
		font-size: clamp(r(20px), r(24px), 24px)
		*
			font-size: clamp(r(20px), r(24px), 24px)
	&.t-20
		font-size: clamp(r(18px), r(20px), 20px)
		*
			font-size: clamp(r(18px), r(20px), 20px)
	&.t-18
		font-size: clamp(r(16px), r(18px), 18px)
		*
			font-size: clamp(r(16px), r(18px), 18px)
.zone-title
	@apply font-bold text-white leading-tight
	font-size: clamp(r(24px), r(32px), 32px)
	margin-bottom: r(25px)
	*
		@apply font-bold text-white
		font-size: clamp(r(24px), r(32px), 32px)
	@screen lg
		font-size: clamp(r(28px), r(36px), 36px)
		*
			font-size: clamp(r(28px), r(36px), 36px)
	&.blue
		@apply text-blue-1E4385
		*
			@apply text-blue-1E4385